
























































































.menu-layout-kids {
  &.isDirty {
    position: relative;
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 5px;
      content: '';
      background: purple;
      transform: translateY(-200%);
    }
  }
}

.menu-layout--title {
  &.has-subtitle {
    margin-bottom: 0.1em;
  }
}
.menu-layout--subtitle {
  font-size: 1.8rem;
  font-weight: 500;
  span {
    font-family: 'motor', serif;
  }
  i {
    font-family: 'Asap', sans-serif;
    // font-weight:300;
    font-size: 0.8rem;
  }
}
